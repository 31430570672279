import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from "../components/Header";
import Footer from "../components/Footer";
import projet1 from "../assets/img/Mockup1.jpeg";
import projet8 from "../assets/img/Mockup theatre ouvert.png";
import projet9 from "../assets/img/Mockup precariteam.png";
import projet10 from "../assets/img/Mockup iphone bricolage.png";
import resume from "../assets/resume/resume NGO Alexis.pdf";


import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();


const toggleTest = () => {
  window.scrollTo({
    top: 0
  });
};


const Home = () => {
  return (
    <div className="app">
      <Header />
      <div className="block">
        <h1>Hello Everyone !</h1>
        <p>Hi, I'm Alexis Ngo, and I have a strong passion for e-commerce. Currently, I'm on track to complete my Master's in E-Business at IIM, a journey I'm fully invested in. I'm eager to leverage my academic background and apply it practically as a future e-commerce/e-store manager. Seeking an apprenticeship opportunity starting in September 2024, I'm driven to contribute meaningfully to a forward-thinking organization, while also honing my skills and knowledge in the field. </p>
        <a class="buttoncv" href={resume} target="_blank">Download my resume</a>
      </div>

      <div className="block3">
        <NavLink to="/otherproject" onClick={toggleTest}>Discover All Work</NavLink>
      </div>

      <div id="block2">
        <h1>Selected Work</h1>
        <h1>From 2022 - 2023</h1>
      </div>

      <div className="grid">
        <div className="grid-items">
          <img src={projet1} alt="Pignon-Ernest" />
          <div className="titleproject">
            <NavLink to="/pignonernest" onClick={toggleTest}>Pignon-Ernest</NavLink>
          </div>
          <div className="alltitle">
            <div className="title">
              <a>UX/UI</a>
              <a >Rebranding</a>
            </div>
            <div className="viewmore">
              <NavLink to="/pignonernest" onClick={toggleTest}>View more</NavLink>
            </div>
          </div>
        </div>
        <div className="grid-items">
          <img src={projet9} alt="Site de Bricolage" />
          <div className="titleproject">
            <NavLink to="/precariteam" onClick={toggleTest}>Precariteam (NGO)</NavLink>
          </div>
          <div className="alltitle">
            <div className="title">
              <a>UX/UI</a>
              <a>Graphic design</a>
            </div>
            <div className="viewmore">
              <NavLink to="/precariteam" onClick={toggleTest}>View more</NavLink>
            </div>
          </div>
        </div>
        <div className="grid-items">
          <img src={projet8} alt="Théatre ouvert" />
          <div className="titleproject">
            <NavLink to="/theatreouvert" onClick={toggleTest}>Théatre Ouvert</NavLink>
          </div>
          <div className="alltitle">
            <div className="title">
              <a>UX/UI</a>
              <a >Rebranding</a>
            </div>
            <div className="viewmore">
              <NavLink to="/theatreouvert" onClick={toggleTest}>View more</NavLink>
            </div>
          </div>
        </div>
        <div className="grid-items">
          <img src={projet10} alt="Site de Bricolage" />
          <div className="titleproject">
            <NavLink to="/sitebricolage" onClick={toggleTest}>A hardware store</NavLink>
          </div>
          <div className="alltitle">
            <div className="title">
              <a>UX/UI</a>
            </div>
            <div className="viewmore">
              <NavLink to="/sitebricolage" onClick={toggleTest}>View more</NavLink>
            </div>
          </div>
        </div>
      </div>



      <Footer />
    </div>

  );
};

export default Home;

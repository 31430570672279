import React from 'react';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import projet1 from "../../assets/img/Chartegraphiqueprecariteam/Precariteam1.png";
import projet2 from "../../assets/img/Chartegraphiqueprecariteam/Precariteam2.png";
import projet3 from "../../assets/img/Chartegraphiqueprecariteam/Precariteam3.png";
import projet4 from "../../assets/img/Chartegraphiqueprecariteam/Precariteam4.png";
import projet5 from "../../assets/img/Chartegraphiqueprecariteam/Precariteam5.png";
import projet6 from "../../assets/img/Chartegraphiqueprecariteam/Precariteam6.png";
import projet7 from "../../assets/img/Chartegraphiqueprecariteam/Precariteam7.png";
import projet8 from "../../assets/img/Chartegraphiqueprecariteam/Precariteam8.png";
import projet9 from "../../assets/img/Chartegraphiqueprecariteam/Precariteam9.png";
import projet10 from "../../assets/img/Chartegraphiqueprecariteam/Precariteam10.png";
import projet11 from "../../assets/img/Chartegraphiqueprecariteam/Precariteam11.png";
import projet12 from "../../assets/img/Chartegraphiqueprecariteam/Precariteam12.png";
import projet13 from "../../assets/img/Chartegraphiqueprecariteam/Precariteam13.png";
import projet14 from "../../assets/img/Chartegraphiqueprecariteam/Precariteam14.png";
import projet15 from "../../assets/img/Chartegraphiqueprecariteam/Maquetteprecariteam.png";


import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

function Precariteam() {
    return (
        <div>
            <Header />
            <div className="projet1">
                <h1>Precariteam (NGO)</h1>
            </div>
            <div className="allprojets">
                <div className="picture1" >
                    <img src={projet1} alt=" " />
                    <img src={projet2} alt="" />
                    <img src={projet3} alt="" />
                    <img src={projet4} alt="" />
                    <img src={projet5} alt="" />
                    <img src={projet6} alt="" />
                    <img src={projet7} alt="" />
                    <img src={projet8} alt="" />
                    <img src={projet9} alt="" />
                    <img src={projet10} alt="" />
                    <img src={projet11} alt="" />
                    <img src={projet12} alt="" />
                    <img src={projet13} alt="" />
                    <img src={projet14} alt="" />
                    <img className="maquette" src={projet15} alt="" />
                </div>
            </div>
            <div className="descriptionprojet1">
                <div className="description-left">
                    <h1>Year</h1>
                    <h2>2023</h2> <br></br>
                    <h1>Techs</h1>
                    <h2>Figma</h2>
                    <h2>Photoshop</h2><br></br>
                    <h1>Roles</h1>
                    <h2>Designer, Developper</h2><br></br>
                </div>
                <div className="description-right">
                    <h1>Concept</h1>
                    <h2>The website of the NGO on student precariousness aims to raise awareness among users about the difficulties faced by students and to promote efforts to combat them. It will also provide information and support to students in precarious situations.</h2>
                </div>
            </div>
            <div className="relatedproject">
                <a href="/theatreouvert">Théâtre Ouvert</a>
                <a href="/theatreouvert">Next Project</a>
            </div>
            <Footer />
        </div>
    );
}

export default Precariteam;
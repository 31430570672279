import React from 'react';
import { Link } from 'react-router-dom';
import Header from "../components/Header";
import Footer from "../components/Footer";


import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

function Contact() {
    return (
        <div id="top">
            <Header />
            <div className="contact">
                <h1>Contact</h1>
            </div>
            <div className="socialmedia">
                <h1>Social Media</h1>
                <div className="namesocial">
                    <a href="https://www.linkedin.com/in/alexis-ngo-6a290214b/">LinkedIn</a>
                    <a href="https://www.instagram.com/njut_sneakers/">Instagram</a>
                    <a href="https://www.behance.net/alexisngo">Behance</a>
                    <a href="https://github.com/Njut12">GitHub</a>
                </div>
            </div>
            <div className="mail">
                <a href="mailto:ngominhnhut1911@gmail.com">Click here to send an email</a>
            </div>
            <Footer />
        </div>
    );
}

export default Contact;
import React from 'react';
import { Navigate } from 'react-router-dom';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import projet1 from "../../assets/img/Mockuppignon/mockupmobileernest.png";
import projet2 from "../../assets/img/Mockuppignon/mockupmobileernest1.png";


import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

function Pignonernest() {
    return (
        <div>
            <Header />
            <div className="projet1">
                <h1>Pignon-Ernest</h1>
            </div>
            <div className="allprojets">
                <div className="picture1" >
                    <img src={projet1} alt=" " />
                    <img src={projet2} alt="" />
                </div>
            </div>
            <div className="descriptionprojet1">
                <div className="description-left">
                    <h1>Year</h1>
                    <h2>2023</h2> <br></br>
                    <h1>Techs</h1>
                    <h2>Figma</h2><br></br>
                    <h1>Roles</h1>
                    <h2>Designer</h2><br></br>
                </div>
                <div className="description-right">
                    <h1>Concept</h1>
                    <h2>The redesign of the Pignon-Ernest website includes a new homepage and an update to its portfolio. The goal is to improve the appearance and user experience.</h2>
                </div>
            </div>
            <div className="relatedproject">
                <a href="/precariteam">Precariteam</a>
                <a href="/precariteam">Next Project</a>
            </div>
            <Footer />
        </div>
    );
}

export default Pignonernest;
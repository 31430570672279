import React from 'react';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import projet6 from "../../assets/img/mockup/Asiantruck.png";


import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

function Cover() {
    return (
        <div>
            <Header />
            <div className="projet1">
                <h1>Logo for a foodtruck</h1>
            </div>
            <div className="allprojets" >
                <div className="picture1" >
                    <img src={projet6} alt=" " />
                </div>
            </div>
            <div className="descriptionprojet1">
                <div className="description-left">
                    <h1>Year</h1>
                    <h2>2022</h2> <br></br>
                    <h1>Techs</h1>
                    <h2>Photoshop</h2>
                    <h2>Illustrator</h2><br></br>
                    <h1>Roles</h1>
                    <h2>Graphic designer</h2><br></br>
                </div>
                <div className="description-right">
                    <h1>Concept</h1>
                    <h2>Creation of a fictional logo for a food truck of choice, here I have chosen an Asian food truck. This logo was created during a graphics design project.</h2>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Cover;
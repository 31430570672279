import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from "../components/Header";
import Footer from "../components/Footer";
import projet1 from "../assets/img/mockup/BookDeanMockup-1.webp";
import projet2 from "../assets/img/mockup/BusChaiseMade3D-1.webp";
import projet3 from "../assets/img/mockup/Ex2-1.webp";
import projet4 from "../assets/img/mockup/Back_Cover_RightCarteinvitation-1.webp";
import projet5 from "../assets/img/mockup/Shaded_Billboard_MockupPomme-1.webp";
import projet6 from "../assets/img/mockup/Asiantruck.png";
import projet8 from "../assets/img/Malaboomsticker.png";
import projet9 from "../assets/img/Mockup page double LV.png";
import '../styles/components/Other.scss';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const toggleTest = () => {
    window.scrollTo({
        top: 0
    });
};

function Otherproject() {
    return (
        <div>
            <Header />
            <div className="otherproject">
                <h1>All work</h1>
            </div>
            <div className="gridother">
                <div className="grid-items2">
                    <img src={projet1} alt="Book cover Dean" />
                    <div className="titleproject">
                        <NavLink to="/Cover">Book cover</NavLink>
                    </div>
                    <div className="alltitle">
                        <div className="title">
                            <a>Graphic design</a>
                        </div>
                        <div className="viewmore">
                            <NavLink to="/Cover" onClick={toggleTest}>View more</NavLink>
                        </div>
                    </div>
                </div>
                <div className="grid-items2">
                    <img src={projet2} alt="Advertissing poster" />
                    <div className="titleproject">
                        <NavLink to="/Advertising" onClick={toggleTest}>Advertissing poster</NavLink>
                    </div>
                    <div className="alltitle">
                        <div className="title">
                            <a>Graphic design</a>
                        </div>
                        <div className="viewmore">
                            <NavLink to="/Advertising" onClick={toggleTest}>View more</NavLink>
                        </div>
                    </div>
                </div>
                <div className="grid-items2">
                    <img src={projet3} alt="Coffee coaster" />
                    <div className="titleproject">
                        <NavLink to="/coaster" onClick={toggleTest}>Coffee coaster</NavLink>
                    </div>
                    <div className="alltitle">
                        <div className="title">
                            <a>Graphic design</a>
                        </div>
                        <div className="viewmore">
                            <NavLink to="/coaster" onClick={toggleTest}>View more</NavLink>
                        </div>
                    </div>
                </div>
                <div className="grid-items2">
                    <img src={projet4} alt="Museum invitation card" />
                    <div className="titleproject">
                        <NavLink to="/invitation" onClick={toggleTest}>Museum invitation card</NavLink>
                    </div>
                    <div className="alltitle">
                        <div className="title">
                            <a>Graphic design</a>
                        </div>
                        <div className="viewmore">
                            <NavLink to="/invitation" onClick={toggleTest}>View more</NavLink>
                        </div>
                    </div>
                </div>
                <div className="grid-items2">
                    <img src={projet5} alt="Poster 4x3" />
                    <div className="titleproject">
                        <NavLink to="/poster" onClick={toggleTest}>Poster for an apple brand</NavLink>
                    </div>
                    <div className="alltitle">
                        <div className="title">
                            <a>Graphic design</a>
                        </div>
                        <div className="viewmore">
                            <NavLink to="/poster" onClick={toggleTest}>View more</NavLink>
                        </div>
                    </div>
                </div>
                <div className="grid-items2">
                    <img src={projet6} alt="Logo" />
                    <div className="titleproject">
                        <NavLink to="/logo" onClick={toggleTest}>Foodtruck logo</NavLink>
                    </div>
                    <div className="alltitle">
                        <div className="title">
                            <a>Graphic design</a>
                        </div>
                        <div className="viewmore">
                            <NavLink to="/logo" onClick={toggleTest}>View more</NavLink>
                        </div>
                    </div>
                </div>
                <div className="grid-items2">
                    <img src={projet8} alt="Logo" />
                    <div className="titleproject">
                        <NavLink to="/Malaboomsticker" onClick={toggleTest}>Malaboom sticker</NavLink>
                    </div>
                    <div className="alltitle">
                        <div className="title">
                            <a>Graphic design</a>
                        </div>
                        <div className="viewmore">
                            <NavLink to="/Malaboomsticker" onClick={toggleTest}>View more</NavLink>
                        </div>
                    </div>
                </div>
                <div className="grid-items2">
                    <img src={projet9} alt="Logo" />
                    <div className="titleproject">
                        <NavLink to="/Lv" onClick={toggleTest}>LV campaign</NavLink>
                    </div>
                    <div className="alltitle">
                        <div className="title">
                            <a>Graphic design</a>
                        </div>
                        <div className="viewmore">
                            <NavLink to="/Lv" onClick={toggleTest}>View more</NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    );
}

export default Otherproject;
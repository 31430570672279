import React from 'react';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import projet1 from "../../assets/img/Mockup page double LV.png";
import projet2 from "../../assets/img/Mockup page A4 LV.png";



import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

function Cover() {
    return (
        <div>
            <Header />
            <div className="projet1">
                <h1>Minimalist Capucine campaign</h1>
            </div>
            <div className="allprojets">
                <div className="picture1" >
                    <img src={projet2} alt=" " />
                    <img src={projet1} alt=" " />
                </div>
            </div>
            <div className="descriptionprojet1">
                <div className="description-left">
                    <h1>Year</h1>
                    <h2>2024</h2> <br></br>
                    <h1>Techs</h1>
                    <h2>Photoshop</h2>
                    <h2>Illustrator</h2><br></br>
                    <h1>Roles</h1>
                    <h2>Graphic designer</h2><br></br>
                </div>
                <div className="description-right">
                    <h1>Concept</h1>
                    <h2>Minimalist campaign for Louis Vuitton Capucine bag</h2>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Cover;
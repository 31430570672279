import React from 'react';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import projet5 from "../../assets/img/mockup/Shaded_Billboard_MockupPomme-1.webp";


import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

function Cover() {
    return (
        <div>
            <Header />
            <div className="projet1">
                <h1>Poster for an apple brand, 4x3</h1>
            </div>
            <div className="allprojets">
                <div className="picture1" >
                    <img src={projet5} alt=" " />
                </div>
            </div>
            <div className="descriptionprojet1">
                <div className="description-left">
                    <h1>Year</h1>
                    <h2>2022</h2> <br></br>
                    <h1>Techs</h1>
                    <h2>Dimension</h2>
                    <h2>Maya</h2>
                    <h2>Photoshop</h2><br></br>
                    <h1>Roles</h1>
                    <h2>Graphic designer</h2><br></br>
                </div>
                <div className="description-right">
                    <h1>Concept</h1>
                    <h2>This 4x3 poster was created during my studies, with the purpose of showcasing the French organic fruit juice and nectar brand "Côteaux Nantais". The bottle was designed on Maya and the texture of the bottle was created on Dimension.</h2>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Cover;
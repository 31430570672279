import React from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import picture from "../assets/img/Moi.png";
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

function AboutMe() {
    return (
        <div id="top">
            <Header />
            <div className="about">
                <h1>About Me</h1>
            </div>
            <div className="descriptionabout">
                <div className="description-left">
                    <h1>Skills</h1>
                    <h2>Photoshop</h2>
                    <h2>Illustrator</h2>
                    <h2>InDesign</h2>
                    <h2>Adobe Premier Pro</h2>
                    <h2>After Effects</h2>
                    <h2>Figma</h2>
                    <h2>Maya, Blender</h2><br></br>

                    <h1>Programming languages</h1>
                    <h2>HTML, CSS, JavaScript</h2>
                    <h2>PHP, MySql</h2>
                    <h2>ReactJS, NodeJs</h2><br></br>

                    <h1>Languages</h1>
                    <h2>English (professional)</h2>
                    <h2>French (fluent)</h2>
                    <h2>Vietnamease (fluent)</h2>
                </div><br></br>
                <div className="description-right">
                    <h1>My career path</h1>
                    <h2>Having graduated with a DUT in Mechanical Engineering and Product Design from the University of Sorbonne Paris Nord in 2019, I chose to pursue a career as a design drafter in the building industry.<br></br><br></br>

                        After obtaining my degree, I began my career at Dani Alu as a design drafter, a family-owned company specializing in aluminum systems for construction and industry. I worked there for one year and three months.<br></br><br></br>

                        In 2021, I decided to change companies to expand my experience and joined Travaux Sur Mesure, a company specializing in metal joinery and locksmithing, also as a design drafter.<br></br><br></br>

                        Although these professional experiences allowed me to develop strong skills and knowledge in the building industry, I decided to resume my studies in early September 2021 in order to pursue a new profession.<br></br><br></br>

                        I have therefore chosen to pursue a Bachelor's degree in Multimedia and Internet Professions, with the aim of discovering new opportunities and flourishing in a field that I am passionate about, which is E-commerce & Marketing digital.<br></br><br></br></h2>
                </div>
            </div>
            <div className="wtf">
                <div className="me">
                    <div className="hobbies">
                        <img src={picture} alt=" " />
                        <h1>NGO Alexis</h1>
                        <h2>E-Commerce / Marketing</h2>
                    </div>
                </div>
                <div className="passion">
                    <h1>My passion is shoes and fashion ! I have a collection of 110 pairs of shoes, all unique ! However, my passion extends beyond just shoes and fashion. Traveling and food are also integral parts of my life. I adore discovering new countries, meeting new people, and immersing myself in different cultures.</h1>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default AboutMe;
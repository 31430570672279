import React from 'react';
import { Link } from 'react-router-dom';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import projet1 from "../../assets/img/pictures/1.JPG";
import projet2 from "../../assets/img/pictures/2.JPG";
import projet3 from "../../assets/img/pictures/3.JPG";
import projet4 from "../../assets/img/pictures/4.JPG";
import projet5 from "../../assets/img/pictures/5.JPG";
import projet6 from "../../assets/img/pictures/6.JPG";
import projet7 from "../../assets/img/pictures/7.JPG";


import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

function Contact() {
    return (
        <div id="top">
            <Header />
            <div className="contact">
                <h1>Pictures</h1>
            </div>
            <div className="pictures">
                <img src={projet1} alt=" " />
                <img src={projet2} alt=" " />
                <img src={projet3} alt=" " />
                <img src={projet4} alt=" " />
                <img src={projet5} alt=" " />
                <img src={projet6} alt=" " />
                <img src={projet7} alt=" " />
            </div>
            <Footer />
        </div >
    );
}

export default Contact;
import React from 'react';
import { NavLink } from 'react-router-dom';

const toggleTest2 = () => {
    window.scrollTo({
        top: 0
    });
};

const Footer = () => {
    return (
        <div className="footer">
            <div className="footerdescription">
                <h1>© NGO Alexis</h1>
                <h1>2023</h1>
            </div>
            <div className="footerlink">
                <NavLink to="/" href="#" onClick={toggleTest2}>Project</NavLink>
                <NavLink to="/about" href="#" onClick={toggleTest2}>About Me</NavLink>
                <NavLink to="/contact" href="#" onClick={toggleTest2}>Contact</NavLink>
            </div>
        </div>
    );
};

export default Footer;
import React from 'react';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import projet1 from "../../assets/img/Mockupthea/Theatre1.png";
import projet2 from "../../assets/img/Mockupthea/Theatre2.png";
import projet3 from "../../assets/img/Mockupthea/Theatre3.png";
import projet4 from "../../assets/img/Mockupthea/Theatre4.png";
import projet5 from "../../assets/img/Mockupthea/Theatre5.png";
import projet6 from "../../assets/img/Mockupthea/Theatre6.png";
import projet7 from "../../assets/img/Mockupthea/Theatre7.png";
import projet8 from "../../assets/img/Mockupthea/Theatre8.png";
import projet9 from "../../assets/img/Mockupthea/Theatre9.png";
import projet10 from "../../assets/img/Mockupthea/Theatre10.png";
import projet11 from "../../assets/img/Mockupthea/Theatre11.png";
import projet12 from "../../assets/img/Mockupthea/Theatre12.png";
import projet13 from "../../assets/img/Mockupthea/Theatre13.png";
import projet14 from "../../assets/img/Mockupthea/Theatre14.png";
import projet15 from "../../assets/img/Mockupthea/Theatre15.png";
import projet16 from "../../assets/img/Mockupthea/Theatre16.png";
import projet17 from "../../assets/img/Mockupthea/Theatre17.png";
import projet18 from "../../assets/img/Mockupthea/Theatre18.png";
import projet19 from "../../assets/img/Mockupthea/Theatre19.png";
import projet20 from "../../assets/img/Mockupthea/Theatre20.png";


import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

function Theatreouvert() {
    return (
        <div>
            <Header />
            <div className="projet1">
                <h1>Théatre Ouvert</h1>
            </div>
            <div className="allprojets">
                <div className="picture1" >
                    <img src={projet1} alt=" " />
                    <img src={projet2} alt="" />
                    <img src={projet3} alt="" />
                    <img src={projet4} alt="" />
                    <img src={projet5} alt="" />
                    <img src={projet6} alt="" />
                    <img src={projet7} alt="" />
                    <img src={projet8} alt="" />
                    <img src={projet9} alt="" />
                    <img src={projet10} alt="" />
                    <img src={projet11} alt="" />
                    <img src={projet12} alt="" />
                    <img src={projet13} alt="" />
                    <img src={projet14} alt="" />
                    <img src={projet15} alt="" />
                    <img src={projet16} alt="" />
                    <img src={projet17} alt="" />
                    <img src={projet18} alt="" />
                    <img src={projet19} alt="" />
                    <img src={projet20} alt="" />
                </div>
            </div>
            <div className="descriptionprojet1">
                <div className="description-left">
                    <h1>Year</h1>
                    <h2>2023</h2> <br></br>
                    <h1>Techs</h1>
                    <h2>Figma</h2>
                    <h2>HTML,CSS, Javascript</h2><br></br>
                    <h1>Roles</h1>
                    <h2>Designer, Developper</h2><br></br>
                </div>
                <div className="description-right">
                    <h1>Concept</h1>
                    <h2>The redesign of the Théâtre Ouvert website aims to improve its appearance and user experience while preserving its visual identity.</h2>
                </div>
            </div>
            <div className="relatedproject">
                <a href="/sitebricolage">A hardware store</a>
                <a href="/sitebricolage">Next Project</a>
            </div>
            <Footer />
        </div>
    );
}

export default Theatreouvert;
import React from 'react';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import projet1 from "../../assets/img/Testo/Maquettetesto.png";
import projet2 from "../../assets/img/Testo/Zoningtesto.png";
import projet3 from "../../assets/img/Testo/Mockupteltesto.png";


import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

function Sitebricolage() {
    return (
        <div>
            <Header />
            <div className="projet1">
                <h1>A hardware store</h1>
            </div>
            <div className="allprojets">
                <div className="picture1" >
                    <img src={projet3} alt=" " />
                    <img src={projet2} alt=" " />
                    <img src={projet1} alt=" " />
                </div>
            </div>
            <div className="descriptionprojet1">
                <div className="description-left">
                    <h1>Year</h1>
                    <h2>2023</h2> <br></br>
                    <h1>Techs</h1>
                    <h2>Figma</h2><br></br>
                    <h1>Roles</h1>
                    <h2>Designer</h2><br></br>
                </div>
                <div className="description-right">
                    <h1>Concept</h1>
                    <h2>Creation of a website mockup dedicated to DIY on mobile devices, including a homepage, a product page, and a blog section. The goal is to provide users with DIY tips and ideas, as well as details on the available products.</h2>
                </div>
            </div>
            <div className="relatedproject">
                <a href="/pignonernest">Pignon-Ernerst</a>
                <a href="/pignonernest">Next Project</a>
            </div>
            <Footer />
        </div>
    );
}

export default Sitebricolage;
import React from "react";
import { Navigate } from 'react-router-dom';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import AboutMe from "./pages/AboutMe";
import Pignonernest from "./pages/projets/Pignonernest";
import Precariteam from "./pages/projets/Precariteam";
import Theatreouvert from "./pages/projets/Theatreouvert";
import Sitebricolage from "./pages/projets/Sitebricolage";
import Otherproject from "./pages/Otherproject";
import Test from './components/test';
import Cover from "./pages/projets/Cover";
import Advertising from "./pages/projets/Advertising";
import Coaster from "./pages/projets/Coaster";
import Invitation from "./pages/projets/Invitation";
import Poster from "./pages/projets/Poster";
import Logo from "./pages/projets/Logo";
import Malaboomsticker from "./pages/projets/Malaboomsticker"
import Lv from "./pages/projets/Lv"
import Pictures from "./pages/projets/Pictures"


const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<AboutMe />} />
        <Route path="/pignonernest" element={<Pignonernest />} />
        <Route path="/precariteam" element={<Precariteam />} />
        <Route path="/theatreouvert" element={<Theatreouvert />} />
        <Route path="/sitebricolage" element={<Sitebricolage />} />
        <Route path="/otherproject" element={<Otherproject />} />
        <Route path="/test" element={<Test />} />
        <Route path="/cover" element={<Cover />} />
        <Route path="/advertising" element={<Advertising />} />
        <Route path="/coaster" element={<Coaster />} />
        <Route path="/invitation" element={<Invitation />} />
        <Route path="/poster" element={<Poster />} />
        <Route path="/logo" element={<Logo />} />
        <Route path="/Malaboomsticker" element={<Malaboomsticker />} />
        <Route path="/Lv" element={<Lv />} />
        <Route path="/Pictures" element={<Pictures />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
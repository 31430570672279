import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

const Header = () => {
    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
        window.scrollTo({
            top: 1
        });
    };

    return (
        <header>
            <nav>
                <div className="allnav">
                    <div className="titleNav">
                        <NavLink to="/" href="#" onClick={toggleMenu}> NGO Alexis</NavLink>
                    </div>
                    <a className="burger" onClick={toggleMenu}>
                        {showMenu ? 'Close' : 'Menu'}
                    </a>
                    <div className={`menu ${showMenu ? 'show' : ''}`}>
                        <NavLink to="/" onClick={(e) => {
                            e.preventDefault();
                            const block2 = document.querySelector('#block2');
                            if (block2) {
                                block2.scrollIntoView({ behavior: 'smooth' });
                            }
                            if (window.location.pathname !== '/') {
                                setTimeout(() => {
                                    window.location.replace('/');
                                });
                            }
                        }}>
                            Work
                        </NavLink>
                        <NavLink to="/pictures" href="#" onClick={toggleMenu}>
                            Pictures
                        </NavLink>

                        <NavLink to="/about" href="#" onClick={toggleMenu}>
                            About Me
                        </NavLink>
                        <NavLink to="/contact" href="#" onClick={toggleMenu}>
                            Contact
                        </NavLink>
                    </div>

                </div>
            </nav>
        </header>
    );
};

export default Header;

import React, { useState } from 'react';

function App() {
    const [rotation, setRotation] = useState(0);

    const rotate = () => {
        setRotation(rotation + 1);
    };

    const circleRadius = 150; // 150px
    const circleCenter = circleRadius + 10; // décalage de 10px pour centrer le texte sur l'arc de cercle
    const circleCircumference = 2 * Math.PI * circleRadius; // circonférence du cercle

    const textLength = 90; // longueur du texte en pixels
    const angle = rotation % 360; // angle de rotation en degrés
    const arcLength = (angle / 360) * circleCircumference; // longueur de l'arc en pixels
    const arcAngle = (arcLength / circleCircumference) * 360; // angle correspondant à la longueur de l'arc

    const textX = circleCenter + circleRadius * Math.cos(angle * Math.PI / 180);
    const textY = circleCenter + circleRadius * Math.sin(angle * Math.PI / 180);

    return (
        <div className="App">
            <div
                className="circle"
                style={{ transform: `rotate(${rotation}deg)` }}
                onAnimationIteration={rotate}
            >
                <span
                    className="text"
                    style={{
                        left: `${textX}px`,
                        top: `${textY}px`,
                        transform: `rotateZ(${arcAngle}deg)`,
                        width: `${textLength}px`
                    }}
                >
                    Ngo Alexis
                </span>
            </div>
        </div>
    );
}

export default App;
